import React, { type FC, useState } from 'react';
import { useField } from 'formik';
import FormikUploadFileWrapper from '../../../../../../../../../components/FormikWrapper/FormikUploadFileWrapper';
import TooltipWrapper from '../../../../../../../../../components/Tooltip/TooltipWrapper';
import FormikCheckBox from '../../../../../../../../../components/FormikWrapper/FormikCheckBox';
import CheckBoxWrapper from '../../../../../../../../../components/form/CheckBoxWrapper';
import ColorStart from '../../../../../../../../../assets/svg/ColorStart';
import { PODCAST_STATUS } from '../../../../../../../../../constant';
import SvgIcons from '../../../../../../../../../assets/svg/SvgIcons';
import { SettingsTypes } from './Settings.Types';
import RadioButton from '../../../../../../../../../components/form/RadioButtonWrapper/RadioButton';
import FormikDateTimeSchedule from '../../../../../../../../../components/FormikWrapper/FormikDateTimeSchedule';

const SettingsStep: FC<SettingsTypes> = ({
  isEdit,
  featuredPodcast,
  onFeaturedPodcast,
  initialPodcast,
  podcastUuid,
  hasAudio,
  isProcessing,
  isSubmitting,
  availableDuration,
}) => {
  const [field, meta, helper] = useField({ name: 'status' });
  const [hasScheduled, setHasScheduled] = useState<boolean>(false);

  const [statusActiveButton, setStatusActiveButton] = useState(field.value.toLowerCase());
  const handleHasScheduled = () => {
    setHasScheduled(!hasScheduled);
  };
  return (
    <div className='col-lg-12'>
      {statusActiveButton !== PODCAST_STATUS.PUBLISH && (
        <div className='upload-title'>When would you like this episode to be published?</div>
      )}
      {!availableDuration && (
        <span className='text-danger error'>
          Please upgrade your account to enable podcast publishing
        </span>
      )}
      <div className='row mb-5'>
        <div className='col-lg-12'>
          {statusActiveButton !== PODCAST_STATUS.PUBLISH && (
            <RadioButton
              id={PODCAST_STATUS.PUBLISH}
              value={PODCAST_STATUS.PUBLISH}
              label='Publish Now'
              name='status'
              checked={field.value.toLowerCase() === PODCAST_STATUS.PUBLISH}
              onChange={(type) => {
                helper.setValue(type);
              }}
              disabled={!availableDuration}
            />
          )}
        </div>
        <div className='col-lg-12'>
          {statusActiveButton !== PODCAST_STATUS.PUBLISH && (
            <RadioButton
              id={PODCAST_STATUS.DRAFT}
              value={PODCAST_STATUS.DRAFT}
              label='Save as Draft'
              name='status'
              checked={field.value.toLowerCase() === PODCAST_STATUS.DRAFT}
              onChange={(type) => {
                helper.setValue(type);
              }}
            />
          )}
        </div>
        <div className='d-flex flex-column col-lg-6 gap-2'>
          {statusActiveButton !== PODCAST_STATUS.PUBLISH && (
            <RadioButton
              id={PODCAST_STATUS.SCHEDULE}
              value={PODCAST_STATUS.SCHEDULE}
              label='Schedule for Later'
              name='status'
              checked={field.value.toLowerCase() === PODCAST_STATUS.SCHEDULE}
              onChange={(type) => {
                helper.setValue(type);
              }}
              disabled={!availableDuration}
            />
          )}
          {(hasAudio || isEdit) &&
            !isProcessing &&
            field.value.toLowerCase() === PODCAST_STATUS.SCHEDULE && (
              <div className='row date-time-schedular px-5'>
                <div className='col-lg-12'>
                  <FormikDateTimeSchedule
                    handleHasScheduled={handleHasScheduled}
                    name='status'
                    isEdit={isEdit}
                    isScheduled
                    isSaveHide
                    isSubmitting={isSubmitting!}
                    isCancelHide={initialPodcast.status.toLowerCase() === PODCAST_STATUS.SCHEDULE}
                    isDraft={initialPodcast.status.toLowerCase() === PODCAST_STATUS.DRAFT}
                  />
                </div>
              </div>
            )}
        </div>
      </div>
      <div className='mb-4 upload-title'>Content Advisory</div>
      <div className='row'>
        {/* TODO REMOVE WHEN THE PHASE ONE END */}
        {/* <div className='col-lg-6'> */}
        {/*  <TooltipWrapper tooltip='Please ensure your bank details are added or link your Stripe account via my profile to receive payments.'> */}
        {/*    <FormikCheckBox name='monetized' label='Monetize this podcast.' /> */}
        {/*  </TooltipWrapper> */}
        {/* </div> */}
        {isEdit && (
          <div className='col-lg-6'>
            <CheckBoxWrapper
              name='featured'
              label='Make it Featured.'
              customElement={
                <span className='m-l-5'>
                  <ColorStart />
                </span>
              }
              checked={featuredPodcast}
              onChange={onFeaturedPodcast}
              id='featured'
            />
          </div>
        )}
      </div>
      <div className='warn-listener'>
        <FormikCheckBox
          name='warnListeners'
          label='Warn listeners about language or mature themes.'
          // disabled={
          //   initialPodcast.status.toLowerCase() === PODCAST_STATUS.PUBLISH
          // }
        />
        {initialPodcast.status.toLowerCase() === PODCAST_STATUS.PUBLISH && (
          <TooltipWrapper
            tooltip='The podcast has already been published, and you cannot make any changes to it.'
            overlayProps={{ placement: 'top' }}
          >
            <SvgIcons iconType='information-icon' />
          </TooltipWrapper>
        )}
      </div>
      {!isEdit && (
        <div className='row'>
          <div>
            <div className='col-lg-6'>
              <TooltipWrapper tooltip='This episode is marked as trailer for your podcast'>
                <FormikCheckBox
                  name='trailer'
                  label='Set this episode as trailer'
                  disabled={podcastUuid !== ''}
                />
              </TooltipWrapper>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SettingsStep;
