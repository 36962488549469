import React, { useRef, useState } from 'react';

import { TOASTER_STATUS } from '../../constant';
import { DragDropFileInterface } from '../../types/dragDropInterface';
import {
  convertBytesIntoKB,
  convertBytesIntoMB,
  getMinutesFromSeconds,
  showToastMessage,
} from '../../utils';
import { validateAudio, validateAudioWithDuration } from '../../utils/formValidationSchema';
import ResizeImage from '../ResizeImage/ResizeImage';

const DragDropFile = ({
  handleFile,
  icon,
  name,
  accept,
  height,
  width,
  maxFileSize,
  sizeIn,
  disabled,
  content,
  subContent,
  isAudio,
  isEpisode,
  maxDuration,
  availableDuration,
  minDuration,
  handleDragDropStyle,
  className,
}: DragDropFileInterface) => {
  const bannerUploadRef = useRef<HTMLInputElement | null>(null);
  const [src, setSrc] = useState<string | null>(null);

  function checkSize(fileSize: number, maxFileSize: number) {
    if (sizeIn === 'KB') {
      return convertBytesIntoKB(fileSize) <= maxFileSize;
    }
    return convertBytesIntoMB(fileSize) <= maxFileSize;
  }

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };
  const handleDrop = (e: React.DragEvent) => {
    if (!disabled) {
      e.preventDefault();

      if (isAudio) {
        const uploadedFile = e.dataTransfer.files[0];
        if (uploadedFile?.type?.split('/')[0] === 'audio') {
          if (uploadedFile && uploadedFile?.size < 1024) {
            showToastMessage(TOASTER_STATUS.ERROR, 'File size should be more than 1KB');
          } else if (
            maxDuration &&
            ((maxDuration ?? 0) < (availableDuration ?? 0)
              ? maxDuration
              : (availableDuration ?? maxDuration)) > (minDuration || 0)
          ) {
            validateAudioWithDuration(
              uploadedFile,
              maxFileSize,
              (maxDuration ?? 0) < (availableDuration ?? 0)
                ? maxDuration
                : (availableDuration ?? maxDuration),
              minDuration
            ).then((response) => {
              if (typeof response === 'string') {
                showToastMessage(TOASTER_STATUS.ERROR, response);
              } else {
                handleFile(uploadedFile);
              }
            });
          } else {
            const result = validateAudio(uploadedFile, maxFileSize);

            if (typeof result === 'string') {
              showToastMessage(TOASTER_STATUS.ERROR, result);
            } else {
              handleFile(uploadedFile);
            }
          }
        } else {
          showToastMessage(TOASTER_STATUS.ERROR, 'Please upload audio file');
        }
      } else {
        const uploadedFile = e.dataTransfer.files[0];
        if (uploadedFile?.type?.split('/')[0] === 'image') {
          if (uploadedFile && !disabled && checkSize(uploadedFile.size, maxFileSize)) {
            setSrc(URL.createObjectURL(uploadedFile));
            handleFile(uploadedFile);
          } else {
            showToastMessage(
              TOASTER_STATUS.ERROR,
              maxFileSize
                ? `File size should not be more than ${maxFileSize}${sizeIn ?? ''}`
                : 'File size too large'
            );
          }
        } else {
          showToastMessage(TOASTER_STATUS.ERROR, 'Please upload image file');
        }
      }

      if (handleDragDropStyle) {
        handleDragDropStyle(false);
      }
    }
  };
  const handleResetFile = () => {
    if (bannerUploadRef.current) {
      bannerUploadRef.current.files = null;
      bannerUploadRef.current.value = '';
    }
  };
  const changeInputFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Use optional chaining to access files[0]
    const selectedFile: File | undefined = event.currentTarget.files?.[0];

    if (isAudio) {
      if (selectedFile?.type?.split('/')[0] === 'audio') {
        if (selectedFile && selectedFile.size < 1024) {
          handleResetFile();
          showToastMessage(TOASTER_STATUS.ERROR, 'File size should be more than 1KB');
        } else if (
          maxDuration && (maxDuration ?? 0) < (availableDuration ?? 0)
            ? maxDuration
            : (availableDuration ?? maxDuration)
        ) {
          validateAudioWithDuration(
            selectedFile,
            maxFileSize,
            (maxDuration ?? 0) < (availableDuration ?? 0)
              ? maxDuration
              : (availableDuration ?? maxDuration),
            minDuration
          ).then((response) => {
            if (typeof response === 'string') {
              handleResetFile();
              showToastMessage(TOASTER_STATUS.ERROR, response);
            } else {
              handleFile(selectedFile);
            }
          });
        } else {
          const result = validateAudio(selectedFile, maxFileSize);

          if (typeof result === 'string') {
            handleResetFile();
            showToastMessage(TOASTER_STATUS.ERROR, result);
          } else {
            handleFile(selectedFile);
          }
        }
      } else {
        handleResetFile();
        showToastMessage(TOASTER_STATUS.ERROR, 'Please upload audio file');
      }
    } else if (selectedFile?.type?.split('/')[0] === 'image') {
      if (selectedFile && checkSize(selectedFile.size, maxFileSize)) {
        setSrc(URL.createObjectURL(selectedFile));
        handleFile(selectedFile);
      } else {
        handleResetFile();
        showToastMessage(
          TOASTER_STATUS.ERROR,
          maxFileSize
            ? `File size should not be more than ${maxFileSize}${sizeIn ?? ''}`
            : 'File size too large'
        );
      }
    } else {
      handleResetFile();
      showToastMessage(TOASTER_STATUS.ERROR, 'Please upload image file');
    }
  };

  const handleResize = (value: any) => {
    handleFile(value);
    setSrc(null);
  };

  const handleDragEnter = () => {
    if (handleDragDropStyle) {
      handleDragDropStyle(true);
    }
  };

  const handleDragLeave = () => {
    if (handleDragDropStyle) {
      handleDragDropStyle(false);
    }
  };

  return (
    <div className='mobile-upload'>
      <button
        type='button'
        className={`d-flex gap-5 ${className} upload-audio-wrapper drop-zone p-3 ${disabled ? 'cursor-not-allowed' : ''}`}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={() => bannerUploadRef?.current?.click()}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      >
        <div className='d-flex justify-content-center align-items-center gap-5'>
          <span className='mb-2'>{icon}</span>
          <div className='d-flex flex-column'>
            <div
              className={`d-flex justify-content-between align-items-center mb-4 ${className?.includes('thumbnail-bg') ? 'flex-column' : ''}`}
            >
              <span className='color-primary'>Drag and Drop</span>{' '}
              <b className='color-primary'>or</b>{' '}
              <button type='button' className='btn-default btn-small mb-0'>
                Select a File
              </button>
            </div>
            {content === '' ? (
              <span className='drop-zone__prompt d-block text-left drop-zone-note'>
                {width && height ? (
                  <>
                    {/* <br /> */}
                    Please upload ({`${width}x${height}`}) resolution file
                  </>
                ) : null}
                {maxFileSize && (
                  <>
                    {/* <br /> */}
                    {` Max file size ${maxFileSize} ${sizeIn ?? ''}.`}
                  </>
                )}
                {isAudio && !!maxDuration && (
                  <>
                    <br />
                    {` Max upload audio duration limit ${
                      isEpisode ? 'per episode' : ''
                    } is ${getMinutesFromSeconds(maxDuration)
                      .replace('hr', 'hours')
                      .replace('min', 'minutes')
                      .replace('sec', 'seconds')}`}
                    .
                    <br />
                    {!!availableDuration &&
                      `Your remaining available upload duration is  ${getMinutesFromSeconds(
                        availableDuration
                      )
                        .replace('hr', 'hours')
                        .replace('min', 'minutes')
                        .replace('sec', 'seconds')}.`}
                    {!availableDuration &&
                      `Your account is currently limited to uploading audio only as a draft.`}
                  </>
                )}
              </span>
            ) : (
              <span className='content'>
                {content}
                <span className='subcontent'>{subContent}</span>
              </span>
            )}
          </div>
        </div>
        <input
          hidden
          type='file'
          ref={bannerUploadRef}
          className='drop-zone__input'
          name={name}
          accept={accept}
          onChange={changeInputFile}
          disabled={disabled}
        />
      </button>
      {src && (
        <ResizeImage
          handleFile={handleResize}
          show={!!src}
          src={src}
          height={height || 0}
          width={width || 0}
          onHide={() => setSrc('')}
        />
      )}
    </div>
  );
};

DragDropFile.defaultProps = {
  disabled: false,
  content: '',
  subContent: '',
  isAudio: false,
  maxDuration: 0,
  handleDragDropStyle: () => {},
  sizeIn: 'MB',
};

export default DragDropFile;
