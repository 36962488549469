import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LogoDark from '../../../../assets/images/logo-dark.png';
import LogoWhite from '../../../../assets/images/logo.png';
import DarkAvatar from '../../../../assets/svg/dashboard/dark/avatar.svg';
import LightAvatar from '../../../../assets/svg/dashboard/light/avatar.svg';
import Plus from '../../../../assets/svg/dashboard/plus.svg';
import IconComponent from '../../../../components/Icon/Icon.Component';
import { useAppContext } from '../../../../context/App/AppContext';
import { IState } from '../../../../redux/types';
import { resendEmail } from '../../../../services/listener/Auth';
import { IUser } from '../../../../types';
import { getLocalStorage } from '../../../../utils';
import UserMenuComponent from './Components/UserMenu/UserMenu.Component';

const HeaderDashboard: React.FC<{ user: IUser }> = ({ user }) => {
  const { isLightTheme, setMobile, mobile } = useAppContext();
  const isUserVerifyEmail = getLocalStorage('isUserVerifyEmail');

  return (
    <header
      style={{ height: `${isUserVerifyEmail?.success === false ? '' : 'auto'}` }}
      className='rbt-dashboard-header rainbow-header header-default header-left-align rbt-fluid-header'
    >
      {isUserVerifyEmail?.success === false && (
        <div className={`verify-banner mb-3 ${isLightTheme ? '' : 'is-light-banner'}`}>
          <IconComponent name='Upload' path='dashboard/Alert/Info.svg' />
          <p className='banner-verify-text'>
            Your account has not been verified yet. Please complete the email verification process
            to gain access to additional Musajjel features.
          </p>
          <button
            type='button'
            onClick={async () => {
              await resendEmail(encodeURIComponent(user.email));
            }}
          >
            Verify Now
          </button>
        </div>
      )}
      <div
        className={`position-relative ${isUserVerifyEmail?.success === false ? 'py-2' : 'py-3'}`}
      >
        <div className='row align-items-center'>
          <div className='col-lg-2 col-md-6 col-7'>
            <div className='d-block d-xl-none mobile-logo'>
              <img src={isLightTheme ? LogoWhite : LogoDark} alt='logo' />
            </div>
          </div>

          <div className='col-lg-10 col-md-6 col-5'>
            <div className='header-right'>
              {/* <IconComponent */}
              {/*  name='Notification' */}
              {/*  path={ */}
              {/*    isLightTheme */}
              {/*      ? `dashboard/light/Notification.svg` */}
              {/*      : `dashboard/light/Notification.svg` */}
              {/*  } */}
              {/* /> */}
              <div className='header-btn d-none d-md-block'>
                <Link
                  className='btn-default bg-gradient-secondary btn-small d-flex align-items-center'
                  to='/dashboard/podcast-add'
                >
                  <img src={Plus} alt='avatar' className='mr--5' /> New Podcast
                </Link>
              </div>

              <div className='mobile-menu-bar mr--10 ml--10 d-block d-xl-none'>
                <div className='hamberger'>
                  <button
                    type='button'
                    className='hamberger-button'
                    onClick={() => setMobile(!mobile)}
                  >
                    <IconComponent path='dashboard/Menu.svg' name='menu' />
                  </button>
                </div>
              </div>

              {/* <GridMenu ToolsData={ToolsData}/> */}

              <div className='account-access rbt-user-wrapper right-align-dropdown'>
                <div className='rbt-user'>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {user?.profilePhotoUrl ? (
                    <img src={user?.profilePhotoUrl} alt={user.firstName} className='user-img' />
                  ) : isLightTheme ? (
                    <img src={DarkAvatar} alt='avatar' />
                  ) : (
                    <img src={LightAvatar} alt='avatar' />
                  )}
                </div>
                <div className='rbt-user-menu-list-wrapper'>
                  <UserMenuComponent />
                </div>
              </div>

              {/* <div className={`expand-btn-grp `}> */}
              {/*    <button */}
              {/*        type='button' */}
              {/*        className={`bg-solid-primary popup-dashboardright-btn ${ */}
              {/*            !rightBar ? "collapsed" : "" */}
              {/*        }`} */}
              {/*        onClick={() => setRightBar(!rightBar)} */}
              {/*    > */}
              {/*        wfff<i className='feather-sidebar right'/> */}
              {/*    </button> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = (state: IState) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(HeaderDashboard);
