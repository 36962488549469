import React from 'react';
import Loader from '../Loader/Loader';

interface IProps {
  progressDescription?: string;
  progressPercentage: number;
}

const UploadProgress: React.FC<IProps> = ({ progressDescription, progressPercentage }) => (
  <div className='record-audio big-height'>
    <div className='drop-zone p-3 d-flex align-items-center justify-content-center gap-4'>
      <span>
        {!progressDescription ? 'Uploading Audio' : progressDescription}
        <br /> ({progressPercentage}% uploaded)
      </span>
      <span className='mt-5 position-relative'>
        <Loader className='aliceblue-loader' />
      </span>
    </div>
  </div>
);

export default UploadProgress;
