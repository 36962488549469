import React, { lazy, Suspense } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import APP_ROUTES, {
  DASHBOARD_APP_ROUTES,
  LISTENER_APP_ROUTES as LISTENER,
  OPEN_APP_ROUTES,
  PODCASTER_APP_ROUTES as PODCASTER,
  PODCASTER_APP_ROUTES,
} from '../constant/appRoute';
import PrivateRoute from '../components/auth/PrivateRoute';
import FullPageLoader from '../components/Loader/FullPageLoader';
import DashboardLayout from '../pages/layout/Dashboard.Layout';
import PodcastPage from '../pages/Dashboard/Sections/Pages/PodcastManagement/ManagementPodcast/ManagementPodcast.Page';
import EmptyComponent from '../components/Empty/Empty.Component';
import NotFound from './NotFound';

const DiscoverPage = lazy(() => import('../pages/Dashboard/Sections/Pages/Discover/Discover.Page'));
const ChangePassword = lazy(
  () => import('../pages/Dashboard/Sections/Pages/ChangePassword/ChangePassword.Page')
);
const UserSettingsPage = lazy(
  () => import('../pages/Dashboard/Sections/Pages/UserSettings/UserSettings.Page')
);
const PricingPage = lazy(() => import('../pages/PricingPage/index'));
const FavoritePage = lazy(() => import('../pages/listener/Favorite/FavoritePage'));
const CreateProfile = lazy(() => import('../pages/Profile/CreateProfile'));
const Payment = lazy(() => import('../pages/podcaster/Payment/PaymentScreen'));

const DownloadPage = lazy(() => import('../pages/listener/Download/DownloadPage'));
const RecentPage = lazy(() => import('../pages/Dashboard/Sections/Pages/Recent/Recent.Page'));
const MyPodcasts = lazy(
  () => import('../pages/Dashboard/Sections/Pages/PodcastManagement/MyPodcast/MyPodcast.Page')
);

const AnalyticsPage = lazy(() => import('../pages/podcaster/Analytics/AnalyticsPage'));

const EditProfile = lazy(() => import('../pages/podcaster/Profile/EditProfile'));

const SubscriptionPage = lazy(() => import('../pages/listener/Subscription/SubscriptionPage'));
const DistributionsPage = lazy(
  () =>
    import('../pages/Dashboard/Sections/Pages/PodcastManagement/Distributions/Distributions.Page')
);

const AllCategories = lazy(() => import('../pages/listener/Category/AllCategories'));
const Category = lazy(() => import('../pages/listener/Category/Category'));

const PodcastDetail = lazy(
  () => import('../pages/Dashboard/Sections/Pages/PodcastDetail/PodcastDetail.Page')
);
// const EpisodeDetail = lazy(() => import('../pages/listener/EpisodeDetail/EpisodeDetail'));

const PodcastDetailPage = lazy(
  () =>
    import('../pages/Dashboard/Sections/Pages/PodcastManagement/PodcastDetail/PodcastDetail.Page')
);

const ManagementEpisodePage = lazy(
  () =>
    import(
      '../pages/Dashboard/Sections/Pages/PodcastManagement/ManagementEpisode/ManagementEpisode.Page'
    )
);
const PodcasterPage = lazy(() => import('../pages/listener/Podcasters/PodcasterPage'));
const AudioEditorPage = lazy(() => import('../pages/podcaster/AudioEditor/AudioEditorPage'));
const SubscriptionScreen = lazy(() => import('../pages/podcaster/Subscription/Subscription'));

const DashboardRouters = () => (
  <Suspense fallback={<FullPageLoader isScreenExist />}>
    <Routes>
      {/* Main dashboard route with layout */}
      <Route
        path={DASHBOARD_APP_ROUTES.DASHBOARD}
        element={
          <PrivateRoute>
            <DashboardLayout />
          </PrivateRoute>
        }
      >
        <Route index element={<Navigate to={DASHBOARD_APP_ROUTES.DISCOVER} replace />} />
        {/* Dashboard home page */}
        <Route
          path={DASHBOARD_APP_ROUTES.DISCOVER}
          element={
            <PrivateRoute>
              <DiscoverPage />
            </PrivateRoute>
          }
        />

        {/* User settings */}
        <Route path={DASHBOARD_APP_ROUTES.SETTINGS}>
          <Route index element={<Navigate to={DASHBOARD_APP_ROUTES.BILLING} replace />} />
          <Route
            path={DASHBOARD_APP_ROUTES.BILLING}
            element={
              <PrivateRoute>
                <UserSettingsPage />
              </PrivateRoute>
            }
          />
          <Route
            path={DASHBOARD_APP_ROUTES.APPEARANCE}
            element={
              <PrivateRoute>
                <UserSettingsPage />
              </PrivateRoute>
            }
          />
        </Route>

        <Route path={DASHBOARD_APP_ROUTES.ALL_CATEGORIES} element={<Outlet />}>
          <Route index element={<AllCategories />} />
          <Route path={DASHBOARD_APP_ROUTES.CATEGORY} element={<Category />} />
        </Route>

        <Route path={DASHBOARD_APP_ROUTES.ALL_PODCASTERS} element={<PodcasterPage />} />

        <Route path={APP_ROUTES.SUBSCRIPTION} element={<SubscriptionScreen />} />

        <Route path={PODCASTER.CONTINUE_EDIT} element={<AudioEditorPage />} />
        {/* Profile Management Routes */}
        <Route path={PODCASTER.CREATE_PROFILE} element={<CreateProfile />} />
        <Route path={PODCASTER.PROFILE} element={<EditProfile />} />

        {/* Listener-specific Routes */}
        <Route path={LISTENER.FAVORITES} element={<FavoritePage />} />
        <Route path={LISTENER.DOWNLOADS} element={<DownloadPage />} />

        {/* Password Update Route */}
        <Route path={OPEN_APP_ROUTES.PASSWORD_UPDATE} element={<ChangePassword />} />

        {/* Pricing Route */}
        <Route path={DASHBOARD_APP_ROUTES.PRICING} element={<PricingPage />} />
        <Route path={APP_ROUTES.PAYMENT} element={<Payment />} />

        {/* Podcast Management */}
        <Route path={DASHBOARD_APP_ROUTES.PODCAST_CREATOR_DETAIL} element={<Outlet />}>
          {/* Main podcast detail page */}
          <Route index element={<PodcastDetailPage />} />

          {/* Nested podcast routes for specific slug */}
          <Route path={DASHBOARD_APP_ROUTES.PODCAST_SLUG} element={<Outlet />}>
            {/* Podcast detail as the index route */}
            <Route index element={<PodcastDetailPage />} />

            <Route path={PODCASTER.DISTRIBUTION} element={<DistributionsPage />} />

            {/* Nested route for specific episode */}
            <Route path={PODCASTER.EPISODE_ID} element={<Outlet />}>
              {/* Episode detail view */}
              <Route
                index
                element={
                  <EmptyComponent
                    title='Not Found Episode'
                    desc='Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
                  />
                }
              />

              {/* Edit episode */}
              <Route path={APP_ROUTES.EDIT} element={<ManagementEpisodePage />} />
            </Route>
          </Route>
        </Route>

        {/* Additional Podcast Management */}
        <Route path={PODCASTER_APP_ROUTES.ADD_PODCAST} element={<PodcastPage />} />
        <Route
          path={`${DASHBOARD_APP_ROUTES.EDIT_PODCAST}/${DASHBOARD_APP_ROUTES.PODCAST_SLUG}`}
          element={<PodcastPage />}
        />
        <Route path={DASHBOARD_APP_ROUTES.MY_PODCAST} element={<MyPodcasts pageType='main' />} />

        {/* Analytics and Subscriptions */}
        <Route path={DASHBOARD_APP_ROUTES.RECENT} element={<RecentPage />} />
        <Route path={DASHBOARD_APP_ROUTES.DRAFT} element={<MyPodcasts pageType='draft' />} />
        <Route path={DASHBOARD_APP_ROUTES.ANALYTICS} element={<AnalyticsPage />} />
        <Route
          path={`${DASHBOARD_APP_ROUTES.ANALYTICS}/${DASHBOARD_APP_ROUTES.REVENUE}`}
          element={<AnalyticsPage />}
        />

        {/* Subscriptions Management */}
        <Route
          path={`${DASHBOARD_APP_ROUTES.SUBSCRIPTIONS}/${DASHBOARD_APP_ROUTES.PODCASTS}`}
          element={<SubscriptionPage />}
        />
        <Route
          path={`${DASHBOARD_APP_ROUTES.SUBSCRIPTIONS}/${DASHBOARD_APP_ROUTES.PODCASTERS}`}
          element={<SubscriptionPage />}
        />

        {/* Podcast Episode Routes */}
        <Route
          path={`${DASHBOARD_APP_ROUTES.PODCAST_SLUG}/${PODCASTER.ADD_EPISODE}/${DASHBOARD_APP_ROUTES.PODCAST_UUID}`}
          element={<ManagementEpisodePage />}
        />
        <Route
          path={`${DASHBOARD_APP_ROUTES.PODCAST_DETAIL}/${DASHBOARD_APP_ROUTES.PODCAST_SLUG}`}
          element={<PodcastDetail />}
        />
        {/* <Route path={DASHBOARD_APP_ROUTES.PODCAST_DETAIL} element={<Outlet />}>
          <Route path={DASHBOARD_APP_ROUTES.PODCAST_SLUG} element={<PodcastDetail />} />
           <Route
           path={`${OPEN_APP_ROUTES.PODCAST_SLUG}/${OPEN_APP_ROUTES.EPISODE_UUID}`}
           element={<EpisodeDetail />}
          />
        </Route> */}

        {/* <Route path={OPEN_APP_ROUTES.NO_NETWORK} element={<NoNetwork />} /> */}
        <Route path={DASHBOARD_APP_ROUTES.NOT_FOUND} element={<NotFound />} />
        {/* <Route path={APP_ROUTES.NOT_FOUND} element={<NotFound />} /> */}
      </Route>
    </Routes>
  </Suspense>
);

export default DashboardRouters;
