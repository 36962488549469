import * as Yup from 'yup';
import { validateSlugSpecialCharacter } from '../../../../../../../utils';
import { getRequiredText } from '../../../../../../../utils/formValidationSchema';

// Upload Audio validation schema
export const uploadAudioValidationSchema = Yup.object({
  uploadedAudio: Yup.string().required(getRequiredText('Uploading or recording audio')),
});

// Visual Media validation schema
export const visualMediaValidationSchema = Yup.object({
  // thumbnail: Yup.string().required(getRequiredText('Thumbnail')),
  // cover: Yup.string().required(getRequiredText('Cover')),
});

// Podcast Details validation schema
export const podcastDetailsValidationSchema = Yup.object({
  podcastTitle: Yup.string()
    .trim()
    .required(getRequiredText('Podcast Title'))
    .min(4, 'Please enter a title between 4 to 255 characters')
    .max(255, 'Please enter a title between 4 to 255 characters'),
  slugUrl: Yup.string()
    .required('Slug URL is required. Please provide a valid slug')
    .min(4, 'The slug should be between 4 to 50 characters')
    .max(50, 'The slug should be between 4 to 50 characters')
    .test('specialCharacter', 'Please use only lowercase letters, numbers, and hyphens', (value) =>
      validateSlugSpecialCharacter(value)
    ),
  language: Yup.string().required('Please select a valid podcast language'),
  // description: Yup.string()
  //   .trim()
  //   .required(getRequiredText('Description'))
  //   .min(10, 'Description should be 10 to 500 characters')
  //   .max(500, 'Description should be 10 to 500 characters'),
});
